import * as React from 'react'

const ContentBoxText = ({ text }) => {
  return (
    <div className="container max-w-3xl mx-auto">
      <div
        className="font-light text-left prose"
        dangerouslySetInnerHTML={{
          __html: text.childMarkdownRemark.html,
        }}
      ></div>
    </div>
  )
}

export default ContentBoxText
