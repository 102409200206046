import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { AddToCart } from '../components/add-to-cart'
import { StoreContext } from '../context/store-context'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../utils/format-price'
import { Container } from '../components/container'
import { Disclosure, Tab } from '@headlessui/react'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import isEqual from 'lodash.isequal'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ObservationList } from '../components/observations-list'
import Ambulance from '../icons/ambulance'
import Clock from '../icons/clock'
import { CubeIcon } from '@heroicons/react/outline'
import { HowItWorks } from '../components/how-it-works'
import { ProductBanner } from '../components/product-banner'
import InfoTabBox from '../components/infobox/info-tab-box'

const ProductPage = ({ data: { shopifyProduct, datoProduct, links } }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    media,
  } = shopifyProduct

  const {
    title,
    observation,
    extra,
    collectionType,
    productInfo,
    faqItem,
  } = datoProduct

  const { t } = useTranslation()

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(shopifyProduct, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === '') {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(shopifyProduct.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, shopifyProduct.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const updatedLinks = Object.assign(
    ...(links.nodes).map(linkItem => ({
      [linkItem.locale]: `${t('our-tests-url', { lng: linkItem.locale })}/${linkItem.category.slug
        }/${linkItem.slug}`,
    }))
  )


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={datoProduct.seoMetaTags}>
      <ProductBanner />
      <Container>
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-14 lg:items-start">
          {/* Image gallery for desktop */}
          <Tab.Group as="div" className="sm:flex flex-col-reverse hidden">
            {/* Image selector */}
            <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {media?.map((image) => (
                  <Tab
                    key={image.id}
                    className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 rounded-md overflow-hidden">
                          <GatsbyImage
                            image={{ ...image.image.localFile.childrenImageSharp[0].gatsbyImageData }}
                            alt="product image"
                            className="w-full h-full object-center object-cover"
                          />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-abel-green' : 'ring-transparent',
                            'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
              {media?.map((image) => (
                <Tab.Panel key={image.id}>
                  <GatsbyImage
                    image={{ ...image.image.localFile.childrenImageSharp[0].gatsbyImageData }}
                    alt="product image"
                    className="w-full h-full object-center object-cover sm:rounded-3xl"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-2 px-4 sm:px-0 sm:mt-16 lg:mt-0">
            <div className="flex justify-between font-serif font-light">
              <h1 className="lg:text-4xl sm:text-2xl text-xl font-medium">{title}</h1>
              <p className="lg:text-3xl sm:text-2xl text-xl">{price}</p>
            </div>

            <div className="mt-4">
              <h3 className="sr-only">Description</h3>

              <div
                className="prose prose-sm sm:prose-base text-sm font-light space-y-6"
                dangerouslySetInnerHTML={{ __html: extra.childMarkdownRemark.html }}
              />
            </div>

            <div className="mt-6 flex sm:flex-col1">
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                className="border-abel-green bg-abel-green text-white rounded-full w-full text-center py-3 text-lg sm:mt-8 mt-4 hover:bg-abel-gray"
              />
            </div>

            {/* Image gallery for mobile */}
            <Tab.Group as="div" className="sm:hidden flex-col-reverse flex mt-6">
              <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                {media?.map((image) => (
                  <Tab.Panel key={image.id}>
                    <GatsbyImage
                      image={{ ...image.image.localFile.childrenImageSharp[0].gatsbyImageData }}
                      alt="product image"
                      className="w-full h-full object-center object-cover sm:rounded-3xl"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                {t('additional-details')}
              </h2>

              <div className="border-t divide-y divide-gray-200">

                {observation && observation.length > 0 && (
                  <Disclosure as="div" key={'observations'}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                            <span
                              className={classNames(open ? 'text-gray-900' : 'text-gray-700', 'text-sm font-medium')}
                            >
                              {t('what-is-tested')}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-gray-700 group-hover:text-gray-800"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel as="div" className="pb-6">
                          <div className="grid grid-rows-2 gap-1">
                            <ObservationList observations={observation} />
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )}

                {collectionType && collectionType.name && (

                  <Disclosure as="div" key={'observations-collection'}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                            <span
                              className={classNames(open ? 'text-gray-900' : 'text-gray-700', 'text-sm font-medium')}
                            >
                              {t('collection-method')}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusSmIcon
                                  className="block h-6 w-6 text-gray-700 group-hover:text-gray-800"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel as="div" className="pb-6">
                          <div class="flex sm:flex-row flex-wrap sm:space-x-2 space-y-2 sm:space-y-0">
                            <div class="flex items-center text-yellow-500 text-sm rounded-3xl border-yellow-500 border-2 px-4 py-1">
                              <p class="text-orange">{collectionType.name}</p>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>)}
                  </Disclosure>
                )}

                <Disclosure as="div" key={'observations-shipping'}>
                  {({ open }) => (
                    <>
                      <h3>
                        <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                          <span
                            className={classNames(open ? 'text-gray-900' : 'text-gray-700', 'text-sm font-medium')}
                          >
                            {t('shipping-title')}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusSmIcon
                                className="block h-6 w-6 text-gray-700 group-hover:text-gray-800"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmIcon
                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel as="div" className="pb-6">
                        <div className="rounded-xl border-dashed border-2 py-4 px-5 font-light space-y-3">
                          <div className="flex space-x-3 items-center">
                            <CubeIcon className="flex-none w-5 h-5" aria-hidden="true" />
                            <p className="text-left">
                              {t('shipping-discreet')}
                            </p>
                          </div>

                          <div className="flex space-x-3 items-center">
                            <Clock className="flex-none w-5 h-5" />
                            <p className="text-left">
                              {t('shipping-time')}
                            </p>
                          </div>

                          <div className="flex space-x-3 items-center">
                            <Ambulance className="flex-none w-5 h-5" />
                            <p className="text-left">
                              {t('free-shipping-details')}
                            </p>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>)}
                </Disclosure>

              </div>
            </section>
          </div>
        </div>
      </Container>
      <HowItWorks />
      <InfoTabBox productInfo={productInfo} faqItems={faqItem.faqBlock} observations={observation} />
    </Layout>
  )
}

export const query = graphql`
  query ($shopifyCode: String!, $shopifyId: String!, $locale: String!) {
    shopifyProduct: shopifyProduct(storefrontId: {eq: $shopifyCode }) {
      id
      title
      handle
      productType
      shopifyId
      storefrontId
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      media {
        ... on ShopifyMediaImage {
          id
          image {
            localFile {
              childrenImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      options {
        name
        values
        shopifyId
      }
    }
    datoProduct: datoCmsProduct(shopifyId: {eq: $shopifyId }, locale: {eq: $locale }) {
      title
      extra: extraNode {
        childMarkdownRemark {
          html
        }
      }
      typeDescription
      shopifyId
      collectionType {
        name
        slug
        tagColor
      }
      slug
      category {
        name
        slug
      }
      observation {
        title: name
        description: descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      videoUrl
      blogTitle
      blogSubtitle
      recommendedArticles {
        contentSummary
        title
        slug
        imageSummary {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        meta {
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
      faqText
      faqItem {
        faqBlock {
          title: question
          description: answerNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      productInfo {
        title
        subtitle
        symptomsTitle
        symptomsTextNode {
          childMarkdownRemark {
            html
          }
        }
        whenToTestTitle
        whenToTestTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    links: allDatoCmsProduct(filter: {shopifyId: {eq: $shopifyId } }) {
      nodes {
        slug
        locale
        category {
          slug
        }
      }
    }
  }
`

export default ProductPage
