import * as React from 'react'

function Ambulance(props) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'Ambulance'}</title>

      <g opacity="0.7">
        <path
          d="M0.721632 0H9.41939C9.8149 0 10.141 0.326123 10.141 0.721633V2.67143H12.632C12.8471 2.67143 13.0276 2.77898 13.1733 2.92469L16.8196 7.00122C16.9271 7.14694 17 7.28918 17 7.46959V10.2486C17 10.6441 16.6739 10.9702 16.2784 10.9702H15.3798C15.0606 12.07 14.0406 12.8818 12.8471 12.8818C11.6502 12.8818 10.6267 12.0873 10.311 10.9702L6.95959 11.0049C6.63 12.0839 5.62041 12.8818 4.43735 12.8818C3.24041 12.8818 2.24122 12.0873 1.9151 10.9702H0.756327C0.360815 10.9702 0.0346928 10.6822 0 10.2833V0.721633C0 0.326123 0.326122 0 0.721632 0ZM12.8506 11.5149C13.5376 11.5149 14.0788 10.939 14.0788 10.2867C14.0788 9.5998 13.5029 9.05857 12.8506 9.05857C12.2018 9.05857 11.6606 9.5998 11.6224 10.2867C11.6224 10.9737 12.1984 11.5149 12.8506 11.5149ZM10.1792 9.52694H10.318C10.6371 8.42714 11.6571 7.61531 12.8506 7.61531C14.0441 7.61531 15.0641 8.43061 15.3833 9.52694H15.5949V7.75755L12.3476 4.11122H10.1827V9.52694H10.1792ZM4.44082 11.5149C5.06531 11.5149 5.66898 10.991 5.66898 10.2867C5.66898 9.52694 5.03408 9.05857 4.44082 9.05857C3.79204 9.05857 3.25082 9.5998 3.21265 10.2867C3.21265 10.9737 3.78857 11.5149 4.44082 11.5149ZM1.44326 9.52694H1.90816C2.22735 8.42714 3.24735 7.61531 4.44082 7.61531C5.64816 7.61531 6.67857 8.44796 6.98388 9.5651H8.70122L8.73592 1.44327H1.44673V9.52694H1.44326Z"
          fill="black"
        />
        <path
          d="M6.40116 4.95468H5.48177V5.87407C5.48177 6.2106 5.21116 6.48121 4.87463 6.48121C4.5381 6.48121 4.26748 6.2106 4.26748 5.87407V4.95468H3.3481C3.01157 4.95468 2.74095 4.68407 2.74095 4.34754C2.74095 4.01101 3.01157 3.7404 3.3481 3.7404H4.26748V2.82101C4.26748 2.48448 4.5381 2.21387 4.87463 2.21387C5.21116 2.21387 5.48177 2.48448 5.48177 2.82101V3.7404H6.40116C6.73769 3.7404 7.0083 4.01101 7.0083 4.34754C7.0083 4.68407 6.73769 4.95468 6.40116 4.95468Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Ambulance
