import * as React from 'react'
import { Fragment } from 'react'
import { Container } from '../container'
import { SectionTitle } from '../section-title'
import { Tab } from '@headlessui/react'
import ContentBoxList from './content-box-list'
import ContentBoxText from './content-box-text'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const InfoTabBox = ( { productInfo, faqItems, observations}) => {
  const infections = observations

  const {
    title,
    subtitle,
    symptomsTitle,
    symptomsTextNode,
    whenToTestTitle,
    whenToTestTextNode,
  } = productInfo

  const tabs = [
    { name: "Infections" },
    { name: symptomsTitle },
    { name: whenToTestTitle },
    { name: "FAQ" },
  ]

  return (
    <Container>
      <SectionTitle title={title} subtitle={subtitle} centered={true} />
      <div>
        <Tab.Group as="div">
          <Tab.List className="grid grid-cols-4 space-x-2 sm:space-x-4 text-xs sm:text-base text-center">
            {tabs.map((tab, index) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-abel-green text-white'
                      : 'bg-beige hover:text-white hover:bg-abel-green',
                    'px-3 py-2 sm:py-4 font-medium rounded-md'
                  )
                }
                key={index}
              >
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels as={Fragment}>
            <Tab.Panel aria-label="Infections">
              <div className="py-10">
                <ContentBoxList list={infections} />
              </div>
            </Tab.Panel>
            <Tab.Panel aria-label="Symptoms">
              <div className="py-10">
                <ContentBoxText text={symptomsTextNode} />
              </div>
            </Tab.Panel>
            <Tab.Panel aria-label="When to test">
              <div className="py-10">
                <ContentBoxText text={whenToTestTextNode} />
              </div>
            </Tab.Panel>
            <Tab.Panel aria-label="FAQ">
              <div className="py-10">
                <ContentBoxList list={faqItems} />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Container>
  )
}

export default InfoTabBox
