import * as React from "react"
import { StoreContext } from "../context/store-context"
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { t, i18n } = useTranslation()
  const { addVariantToCart, loading } = React.useContext(StoreContext)
  const prefix = i18n.language === 'en' ? '' : '/' + i18n.language

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity).then(() => navigate(prefix + '/cart'))
  }

  return (
    <button
      type="submit"
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {available ? t('product-form-add') : t('product-form-no-stock')}
    </button>
  )
}
