import * as React from 'react'

function Clock(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'Clock'}</title>

      <g opacity="0.7">
        <path
          d="M7 0C3.12924 0 0 3.12924 0 7C0 10.8708 3.12924 14 7 14C10.8708 14 14 10.8736 14 7C14 3.12638 10.8736 0 7 0ZM7.5955 12.809V12.5112C7.5955 12.1849 7.32638 11.9157 7 11.9157C6.67362 11.9157 6.4045 12.1849 6.4045 12.5112V12.809C3.66176 12.5284 1.47157 10.3382 1.19387 7.59836H1.46012C1.7865 7.59836 2.05562 7.32924 2.05562 7.00286C2.05562 6.67648 1.7865 6.40736 1.46012 6.40736H1.19387C1.47444 3.66462 3.66462 1.47444 6.4045 1.19673V1.46012C6.4045 1.7865 6.67362 2.05562 7 2.05562C7.32638 2.05562 7.5955 1.7865 7.5955 1.46012V1.19387C10.3382 1.47444 12.5284 3.66462 12.8061 6.4045H12.5112C12.1849 6.4045 11.9157 6.67362 11.9157 7C11.9157 7.32638 12.1849 7.5955 12.5112 7.5955H12.809C12.5284 10.3382 10.3382 12.5284 7.5955 12.809Z"
          fill="black"
        />
        <path
          d="M6.99989 3.5752C6.67351 3.5752 6.40439 3.84432 6.40439 4.1707V6.79033L3.69314 10.1286C3.48414 10.3977 3.54426 10.7527 3.78189 10.9617C4.114 11.2051 4.46615 11.0218 4.58639 10.8729L7.47801 7.3572C7.53813 7.26845 7.59826 7.1482 7.59826 6.99933V4.1707C7.59539 3.84145 7.32914 3.5752 6.99989 3.5752Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Clock
